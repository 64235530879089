@import url("https://fonts.googleapis.com/css2?family=Fustat:wght@200..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.ticker {
  animation: scroll 10s linear infinite;
  display: flex;
}

@keyframes scroll {
  to {
    transform: translateX(-50%);
  }
}

.hover-lift {
  transition: transform 0.1s ease-in-out;

}
.hover-lift:hover {
  transform: translateY(-5px);
}